import React from "react";
import { Page } from "../components/Page";

export default function HistoryPage() {
  return (
    <Page title="Geschichte">
      <div className="prose max-w-4xl mx-auto">
        <p>
          United Brass Big Band, die Big Band des Gymnasiums an der Wolfskuhle:
        </p>
        <p>
          Eine (doch nicht ganz so) kurze Geschichte der Schul-Big-Bands und der
          mit ihr verbundenen Projekte
        </p>
        <p>
          1993 wurde die"United-Brass-Big-Band" als "Human(n)-Brass-Bigband" am
          Carl-Humann-Gymnasium von 15 Schülern und von Robert Maruhn, der dort
          Musiklehrer war, gegründet. 1994 stellte sich die Band erstmals
          öffentlich im Konzert vor und hatte sofort durchschlagenden Erfolg.
          Die Band, in der Schülerinnen und Schüler von der fünften Klasse bis
          hin zu Abiturienten an Trompete, Saxophon, Querflöte Posaune oder in
          der Rhythmusgruppe spielen, wuchs daher auch schnell an bis zu im
          Moment 448Mitgliedern und spielte zu vielen Anlässen ihre ganz eigene
          Mischung von Jazz, Blues, Rock und Soul.
        </p>
        <p>
          1997 nahm die noch junge Band ihre erste Maxi-CD "Moondance" auf, die
          schnell ausverkauft war. Im Dezember 1997 war Paul Kuhn, der Mann am
          Klavier, zu Gast bei einer Bandprobe und probte mit den jungen
          Musikern das Stück "Take five" von Dave Brubeck ein. Im Jahre 2000
          wurde die zweite CD "Off the track..." aufgenommen, die mit "Long
          train running" von den Doobie Brothers das erste von Schülern
          selbstarrangierte Stück enthielt. Die Fassung von Peter Wecking, Jan
          Kolodziej und Tobias Schuchert ist bis heute fester Bestandteil des
          Bandrepertoires und die Standardzugabe in den Jahreskonzerten. Im
          August 2000 errang die Band den ersten Platz beim "Sommer-Grand-Prix
          der Schulorchester" im CentrO und gewann den Hauptpreis, eine weitere
          CD-Produktion, die 2001 aufgenommen wurde. Die Jury lobte das
          "vielseitige Programm, die eigenen guten Arrangements, die sauber,
          kraftvoll und dynamisch gespielt sowie mit viel Spaß präsentiert
          sind".
        </p>
        <p>
          Im Februar 2002 präsentierte die Big-Band daraufhin ihre dritte CD
          ("Human(n)-Brass 3"). Ein zusätzlicher PC-Videotrack zeigt die
          Zusammenarbeit der Big-Band mit einer Tanzgruppe, die von der
          Sportlehrerin Heidi Klein geleitet wurde, bei "Rock around the clock".
        </p>
        <p>
          Durch den Wechsel von Robert Maruhn zum Gymnasium an der Wolfskuhle
          wurde ab Sommer 2002 die Human(n)-Brass-Big-Band als Musik-Projekt für
          zwei Schulen angeboten und in "United-Brass-Big-Band" umgetauft.
          Nunmehr nehmen Schülerinnen und Schüler beider Schulen teil. Zugleich
          wurde im Jahre 2003 eine Nachwuchs Big-Band gegründet, die
          "Brassroots". Die Leitung der "Brassroots" übernahm alsbald Johan
          Malan, als Trompeter Bandmitglied von der ersten CD an. Inzwischen
          wurde diese Band erneut geteilt in die "Brassroots", nunmehr die
          Unterstufen-Big-Band unter der Leitung von Saskia Malan (geb. Hensel),
          ebenfalls ehemalige Trompeterin der Big Band, und die
          "Budding-Brass-Big-Band" von Johan Malan, in der jetzt etwa 25
          Schülerinnen und Schüler der 7. bis 10 Klasse spielen.
        </p>
        <p>
          2005 nahm die "United-Brass-Big-Band" die vierte (oder erste
          gemeinsame) CD "What is hip" im Tonstudio der Robert Schumann
          Hochschule in Düsseldorf auf. Neben den zwei Instrumentalstücken
          "Children of Sanchez" und "What is hip" wurden von den drei
          Sängerinnen der Band (Leonie und Annika Schliesing sowie Susanne Tyra)
          Stücke von Norah Jones, Robbie Williams, Aretha Franklin und den
          Blood, Sweat and Tears dargeboten. Aufgenommen und gemischt wurde die
          CD von Peter Wecking, Pianist der ersten Besetzung der Band.
        </p>
        <p>
          Im Jahr 2002 begann auch die Zusammenarbeit der Big-Band mit der
          Theatergruppe der Wolfskuhle. Das erste Projekt "Dreigroschenoper"
          (Brecht/Weill) wurde 2004 nach fünf erfolgreichen Aufführungen in der
          Aula auch im Schauspielhaus Bochum gezeigt. 2006 folgte das Musical
          "West Side Story" von Leonard Bernstein. Die Musik beider Produktionen
          wurde von jeweils 4 Bläsern und einer Rhythmusgruppe mit Robert Maruhn
          eingeprobt und dann live zum Schauspiel, das von Alfred Kleis und
          Norbert Weber geleitet wurde, dargeboten. Nachdem auch die West Side
          Story fünf ausverkaufte Aufführungen erlebte, wurde 2007 die dritte
          Co-Produktion begonnen. Leider erkrankte und verstarb während der
          Proben Alfred Kleis im Januar 2009, das Musical "Hair" wurde daher mit
          dem ehemaligen Woku-Schüler Roland Riebeling als Schauspielleiter
          beendet und im März 2009 sechs Mal vor ausverkauftem Haus (und damit
          vor über 3000 Zuschauern) gespielt. Bei "Hair" wurde die fünfköpfige
          Combo (mit Gitarre, Bass, Klavier, Schlagzeug und Perkussion) durch
          einen 14-köpfigen Chor unter der Leitung von Christoph Weßkamp
          ergänzt.
        </p>
        <p>
          Drei Stücke aus der West Side Story wurden 2007 auf dem ersten "Wir
          werden Helden"-Sampler der Klangweltstudios Essen (zusammen mit
          Stücken von 4 anderen Schulen) aufgenommen und veröffentlicht, auf dem
          zweiten Sampler 2009 finden sich zwei aktuelle Stücke der
          United-Brass-Big-Band ("The Chicken", ein instrumentales
          Jazz-Rock-Stück und die von Fabian Brinkmann arrangierte Skanummer
          "Superspy", gesungen von Franka Özkan und Annika Schliesing) sowie das
          A-capella-Stück "That lonesome road" (von acht Big-Band-Mitgliedern
          gesungen) und ein erstes selbstkomponiertes Stück des neuen
          Schulorchesters unter der Leitung von Johan Malan.
        </p>
        <p>
          In den Jahreskonzerten 2010 war Bernie Conrads zu Gast, der als
          Bandleader der "Bernies Autobahnband" und Songschreiber für Stefan
          Stoppok, Peter Maffay u.a bekannt geworden ist . Er spielte vier
          Stücke mit unserer Band, kam super beim Publikum an und gab daher
          jeweils noch eine Zugabe.
        </p>
        <p>
          Auch in den Jahren zuvor waren immer wieder Gäste Bestandteil der Show
          wie z.B. die 78Twins (Bastian und Benny Korn) oder Bernd Krämer
          (Gitarrist und Trompeter u.a. bei Albert Mangelsdorf und Klaus Lage).
        </p>
        <p>
          Auch aus der Band entstandene oder durch Mitglieder mit ihr verbundene
          Combos konnten sich immer wieder erfolgreich als Gäste bei diesen
          Konzerten einbringen, sei es "Vokuhila", "Omas Zwerge", "Sprout",
          "Delite", die "Gülden Flamingos", "Ten Cent Pistol", A-Back oder
          "Querbeat".
        </p>
        <p>
          2011 waren die Sängerin "Andrea Held" und "Sorgenkind" , die Band
          unseres Drummers Nico Nannen und unseres Bassisten Moritz Weiß an den
          Konzerten beteiligt.
        </p>
        <p>
          Zudem wurde die neue Live CD "United-Brass Live" mit Ausschnitten der
          Konzerte 2010 präsentiert. Darauf sind 9 Stücke der Big Band, eines
          der "Gülden Flamingos" und drei Songs der Combo mit Bernie Conrads.
          Sie kann wie auch alle anderen CDs für 9,98 Euro über{" "}
          <a href="mailto:maromaruhn@t-online.de">maromaruhn@t-online.de</a>{" "}
          bezogen werden. Aufgenommen und wunderbar abgemischt von unserem Ex
          -Gitarrero Manuel Moritz, das Cover dazu inklusive der Fotos wurde von
          unserem ehemaligen Saxophonisten Nico Kleemann gestaltet.
        </p>
        <p>
          Eine United-Brass-Combo unter der Leitung von Philipp Frenzel (mit
          Martin Hornshaw, Michel Gross und Moritz Weiß) begleitete die sechs
          Aufführungen unseres Musicals "Little Shop of Horrors", das am
          30.9.2011 Premiere hatte. Die Projektleitung lag neben Philipp Frenzel
          bei Tobias Heil, Lea Menth und Robert Maruhn. Ca. 2500 Zuschauer
          feierten die Band und die Schauspielgruppe, in der neben aktuellen
          Big-Band-Mitgliedern wie Alexandra Wagner und Lisa Schmidt auch unsere
          Ehemalige Leonie Schliesing wieder dabei war. Sie sang gewohnt
          souverän aus dem Hintergrund die Rolle der fleischfressenden Audrey
          II.
        </p>
        <p>
          Auf der Bühne hat die Band bei ihren Jahreskonzerten in der Aula, auf
          zahlreichen Stadtteilfesten wie dem Burgfest in Burgaltendorf oder dem
          Weihnachtsmarkt in Steele inzwischen so viel Live-Erfahrung gewonnen,
          dass sie seit 2008 bereits sechsmal vom Schulministerium zur
          Begleitung von Preisverleihungen oder dem Bildungssymposium (in der
          Messehalle der Gruga) eingeladen wurde. Die enorme Power der Live-Band
          resultiert unter anderem auch aus den seit 23 Jahren stattfindenden
          Big-Band-Fahrten in die Jugendherberge Morsbach, um dort intensiv und
          konzentriert das neue jeweils Programm einzuproben.
        </p>
        <p>
          Im Sommer 2011 stellte die Big-Band bei den Jahreskonzerten auch zum
          ersten Mal ihr neues Projekt, die "Wolfpack-Drumline" vor, die aus 9
          Drummerinnen und Drummern besteht, 2014 von Philippe Labonde und ab
          Herbst 2014 von Jonas Hendrichs geleitet wird und gehörig Dampf macht.
          Das Projekt war vom ersten Ton an ein Riesenerfolg und wird sicher
          weitere Früchte tragen. Seit 2015 gibt es nunmehr den Ableger "The Big
          Bang Drummers" mit 9 Nachwuchsdrummern/innen der Klassen 5-7 unter der
          Leitung von Jannick Suhr.
        </p>
        <p>
          Die Jahreskonzerte 2012 waren dreimal ausverkauft und waren mit über
          zweieinhalb Stunden Programm der Drumline, Budding-Brass-Big-Band,
          United-Brass-Big-Band und unserem wunderbaren "Stargast" Leonie
          Burgmer wieder ein voller Erfolg. Zum ersten Mal wurden auch drei
          Stücke gespielt, die im Projektkurs arrangiert wurden (Hard to handle,
          I´d rather go blind und The Race). Mit The Race, Haus am See und einer
          erweitereten Live-Version von Long train running waren erstmals
          gemeinsame Stücke der Drumline mit der Big-Band zu hören, der mächtige
          Klang dieser Verbindung stellte alles vorherige in den Schatten.
        </p>
        <p>
          Die Jahreskonzerte 2013 waren erneut ein großer Erfolg und wieder
          komplett ausverkauft. "Budding Brass" und die "Wolfpack-Drumline"
          waren ebenso mit von der Partie wie "Ten Cent Pistol" als Gast mit 3
          Stücken. Acht von SchülerInnen arrangierte Stücken zeugen von der
          wachsenden Bedeutung des Projektkurses. "Pencil full of lead" (Paolo
          Nutini, arr. von Lea Syring und Jana Wolter) sorgte für gute Laune,
          "Dani California" von den Red Hot Chily Peppers (Arr. Marius Meyer)
          war ein knalliger Opener und der alte Eagles-Schieber "Hotel
          California" (Arr. Moses Kramer, Germain Machado, R.Maruhn) entfaltete
          sich mit 4 Gitarren zu einer gigantischen Schlussnummer, bevor die
          Zugaben "Long train running" mit der Drumline und "Severn bridges
          road" als A-Capella-Stück das Konzert beendeten.
        </p>
        <p>
          Zudem gibt es eine klasse neue CD der Vorjahreskonzerte
          (United-Brass-Big-Band Live 2012, erneut von Manuel Moritz aufgenommen
          und von Nico Kleemann bebildert).
        </p>
        <p>
          Im Oktober 2013 brachten Robert Maruhn als musikalischer Leiter,
          Tobias Heil als Regisseur und Lea Menth als Choreographin gemeinsam
          das Filmmusical "Across the Universe" als Welturaufführung für vier
          Vorstellungen auf die Woku-Bühne. Die 24 Beatlessongs wurden in der
          9-köpfigen Combo teils komplett neu arrangiert und gaben ganz andere
          Blicke auf die mitunter 50 Jahre alten Songs frei. Tobias feinfühlige
          Regie, die mitreissenden Tänze und die starken Gesangsleistungen
          (unter Mithilfe von Valerie Quade als Vocalcoach) des hochengagierten
          Ensembles sorgten an allen 4 Aufführungen für "Standing ovations".
        </p>
        <p>
          Im Dezember 2013 war die "Dutch Swing College Band" zu Gast, am
          28.2.2014 spielte die WDR-Big-Band ein wunderbares Konzert in der Aula
          der Wolfskuhle, bei der United-Brass die Vorgruppe abgab und sich
          tapfer behaupten konnte. Zuvor gab es einen gemeinsamen Workshop mit 4
          Coaches der WDR-Big-Band (Trompeter Andy Haderer, Posaunist Ludwig
          Nuss, Saxophonistin Karolina Strassmeyer und Bassist John Goldsby),
          die mit unseren Sections 2 Stücke geprobt haben.
        </p>
        <p>
          Im Herbst 2014 haben 3 wiederum ausverkaufte Jahreskonzerte das
          20-jährige Bandjubiläum eingeläutet, wie zum 15-jährigen Jubiläum war
          wieder eine Ehemaligencombo von ca. 40 Mitwirkenden mit von der
          Partie, die 4 Wochen vorher in Morsbach beim ersten
          "Ehemaligen-Morsbach" mit 55 Gästen die 4 Stücke eingeprobt hatte.
        </p>
        <p>
          In den Konzerten gab es so viele selbstarrangierte Stücke wie noch nie
          und dass die Band nunmehr um ein Jahr "G8-verjüngt" ist, merkte man
          praktisch gar nicht. Insgesamt waren über 100 Mitwirkende auf der
          Bühne und haben ein würdiges Jubiläum gestaltet. Außer "United-Brass"
          waren natürlich die "Budding-Brass", die "Wolfpack-Drumline" und die
          Band "ABack" (mit Florian Dick, Nikita Morlang sowie Robin und Jannick
          Suhr") dabei. Denkwürdig wohl auch Roberts Rapper Auftritt mit Juri
          und Kai zu "Empire state of mind".
        </p>
        <p>
          Das neue Musical ("Spring awakening"), wieder von Tobias Heil und
          Robert inszeniert, hatte am 28.5.2015 Premiere. Es folgten 5 weitere
          Aufführungen u.a. in der Weststadthalle und "unplugged" bei den
          Essener Theatertagen in der Casa. Über 2500 Zuschauer hatten ihre
          Freude an dem schweren Drama mit toller Musik. Mit Jan Liermann und
          Johannes Boegershausen beteiligten sich auch 2 Referendare an der
          Band, Manuela Klöppel und Delia Weber halfen bei den Proben und der
          Choreographie.
        </p>
        <p>
          Die Jahreskonzerte 2015 hatten in den 3 Aufführungen wieder knapp 1500
          Gäste und die Band wurde von Abend zu Abend besser. Noch nie hatten
          wir so viele Sängerinnen (6) und Sänger/Rapper (5). Das hat wirklich
          Maßstäbe gesetzt. 7 Stücke von diesen Abenden (u.a. auch der Uptown
          Funk mit Alexander Teng) befinden sich auch auf der CD "United Brass
          live 2013-14-15"
        </p>
        <p>
          Die Jahreskonzerte 2016 waren ein voller Erfolg: am 23.9., 25.9. und
          27.9.16 waren ca. 1200 Zuschauer in der Aula und sahen ein
          vielseitiges Programm mit einigen schönen Highlights wie der
          unvergessenen "3-4-Mann-Leuchtstick-Drumperformance", dem ersten
          Einsatz einer Kollegin als Frontsängerin (Tamara Malzahn mit einer
          Balkanversion von "Bella ciau"), ein Wahnsinnsgitarrensolo von Gregor
          über "Gravity" von John Mayer, ein kompaktes jazziges "Spain von
          "Return to forever" und auf der anderen Seite wunderbar ausgefuchste
          Vocals bei "Rum an Coca Cola", "I won´t give up" und "The parting
          glass".
        </p>
        <p>
          Es gibt ab jetzt auch die neue Live-CD "United Brass 13 14 15", zu
          bestellen natürlich bei Robert per E-Mail.
        </p>
        <p>
          2017 brachte neben mehreren kleineren externen Konzerten (Isinger
          Feld, Kray or die, Kultur im Laden in Altendorf, Unfallkasse NRW ...)
          die 3 proppevollen Jahreskonzerte im Herbst. Nach einem Intro der
          Wolfpack-Drumline im Dunklen heizten die Budding Brass mit 5 Stücken
          an, bis die United Brass Big Band das Steer mit der
          Sportstudio-Melodie "Up to date" übernahm.
        </p>
        <p>
          Danach ging es wild durch alle Stilrichtungen: unter anderem Shakey
          ground von den Temptations mit druckvollen Bläsern und vielen Soli,
          Stone cold sober mit E-Geige, Somethings got a hold on me mit
          Powervocals von Paula Kaminski, der Cantina Band-Song (im Projektkurs
          von Lucas und Lukas arrangiert wie auch 4 weitere Stücke), Macklemores
          Downtown von Gregor (und Kaldije) gerappt, eine swingendes
          instrumentales Moondance, ein düsteres Take me to church mit Kaldije,
          Disco mit Regina (Dance with somebody, I´m so excited), eine
          wunderschöne Ballade von Leon gesungen (Call you home) sowie als
          Abschluss eine mächtige Version von "Purple rain", ein letzter Gruß an
          den verstorbenen Prince. Gregor lief dabei als Sänger und
          Sologitarrist zur absoluten Hochform auf. Norbert Weber ist seitdem
          Purple Rain Fan!
        </p>
        <p>
          Nach der obligaten Long Train Zugabe mit Drumline klangen die Abende
          besinnlich mit einer Akustikgitarren Version von Bob Marleys
          "Redemption Song" aus, gesungen von 15 Schülerinnen und Schülern.
        </p>
        <p>Alles in allem rundherum herzerwärmende Konzerte.</p>
        <p>
          Im November brachte wir dann unser siebtes Musical "The Blues Brothers
          - Showband und Revue" auf die Bühne. Tobias Heil sorgte wieder (zum
          vierten Male) in unfassbarer Weise für die Regie, Dominik Naroska
          kümmerte sich hingebungsvoll um die Tänze und ich leitete die Band an,
          die Bühnenleitung der Band übernahm dann Lucas Gruß mit großer
          Konzentration. Alle 4 Vorstellungen waren ausverkauft und brachten
          eine prächtige Stimmung durch die schrägen Dialoge und die wunderbaren
          Blues- und Soulnummern. Die 12-köpfige Combo sorgte für ordentlich
          Alarm, die Schauspieler gaben alles, allen voran natürlich mit
          beeindruckender Präzision Leon Kianzad als Jake und Jannick Suhr als
          Elwood, aber auch die anderen SolosängerInnen wie Alex Teng (als Cab
          Calloway), Kaldije el Merhi (als Aretha), Miriam Albrecht als
          Predigerin und mit Laura Glawe zusammen bei "Soothe me" sowie Louisa
          Birngruber mit "Stand by your man" legten wunderbare Auftritte hin.
          Unvergessen auch Nikita Morlang mit der wohl abgefahrensten Version
          von "Quando quando". Bilder gibt es unter "Fotos", Hörbeispiele folgen
          bald. Nichts an Schule macht in meinen Augen mehr Sinn als solche
          Produktionen.
        </p>
        <p>
          Am 27., 28. und 29.9.2019 fanden die drei Jahreskonzerte zum
          25-jährigen Bestehen der Big Band statt. Ich habe mit diesen
          Wahnsinnskonzerten die Leitung der Band an Johan und Saskia abgegeben,
          in der Hoffnung, dass die Band auch weiter so gedeihen möge! Es war
          ein sehr bewegender Abschied für mich, der Text von Hendrik Leyerer
          dazu (unter Presse) und die Bilder (bei Fotos) geben das besser wieder
          als alle Worte.
        </p>
        <p>
          An die 70 Ehemalige kamen Ende August in einer Jugendherberge
          zusammen, hielten Rückschau, feierten das Jubiläum und übten Stücke
          ein, die sie dann auch beim bunten Wochenende zum 25-Jährigen
          präsentieren wurden. Als Sahnehäubchen gab es dazu die wilden
          Trommeleskapaden der „Wolfpack-Drumline“ unter der Leitung des
          ehemaligen Schülers Jonas Hendrichs. Die Konzerte waren der absolute
          Hammer, mit einer aktuellen United-Brass-Big-Band in Topform, einer
          Ehemaligen-Combo von je ca. 50 Musikern, die so geschmeidig gezockt
          haben, als wären sie nie weg gewesen, der Wahnsinns-Drumline (z.B. bei
          Can´t hold us!) und den kleinen Brassroots, sowie weiteren Gästen wie
          Bassi und Benny Korn, Michael Zerger, Michael Minholz, Philipp
          Frenzel, Reinhard Monno und meiner Frau am Bass! Für mich (Robert)
          nach 25 Jahren ein sehr bewegender Abschluss und für immer
          unvergesslich! Und auch nicht zu vergessen: ein super Sound von Paddy,
          so muss es sein! Grandios! Tausend Dank an alle, die daran mitgewirkt
          haben!
        </p>
        <p>So long, baby! Long train running....</p>
        <p>
          <strong>Aufnahmen</strong>
        </p>
        <ol>
          <li>
            CD: „Moondance“ (1997, Auflage 300)
            <br />( „Cantaloupe Island“,“ Moondance“, „Red house“, Evil ways “,
            “I don´t like mondays”)
          </li>
          <li>
            CD: „Off the track...“ (2000, Auflage 500)
            <br />
            („Long train running“, You can leave your hat on“, „Curtain
            up““James Bond Theme”, “Mission Impossible”).
          </li>
          <li>
            CD: “Human(n)-Brass 3” (2002, Auflage 500)
            <br />
            (“Smooth”, “Rock around the clock”, Big-Band-Jam”, Smoke on the
            water”, “Jolly Coppers On Parade”, Videotrack)
          </li>
          <li>
            CD: United Brass Big Band: “What is hip” (2005, Auflage 500)
            <br />( What is hip, Respect, Don´t know why, Spinning wheel,
            Children of Sanchez, Let me entertain you)
          </li>
          <li>
            CD: Schul-Sampler “Wir werden Helden” (Klangwelt Studio Essen, 2007)
            mit drei Stücken aus der “West Side Story” (Cool, Somewhere, I feel
            pretty)
          </li>
          <li>
            CD: Schul-Sampler “Wir werden Helden Vol. II” (Klangwelt Studio
            Essen, 2009) mit 3 Stücken der Big Band (Superspy, The Chicken,
            A-Capella-Combo: That lonesome road) und 1 x Schulorchester
          </li>
          <li>
            CD: “United-Brass Live“ (13 Songs- 9x United Brass, 1x „Gülden
            Flamingos“ und 3 Stücke mit Bernie Conrads)
            <br />
            (2011)
          </li>
          <li>
            CD "United-Brass-Big-Band Live 2012", mit 13 Stücken aus dem
            letztjährigen Programm. Cover wie bei den letzten beiden von Nico
            Kleemann. (2013)
          </li>
          <li>
            CD: "United Brass 13 14 15" - 18 Songs - das Beste aus drei
            Jahres-Big-Band-Konzerten live, ungeschminkt und voller Power!
            Booklet von Nico Kleemann, was auch sonst! (2016)
          </li>
          <li>
            CDs und DVDs der Musicals Hair, Little Shop of horrors, Across the
            universe und Spring awakening. Blues Brothers in Produktion.
          </li>
        </ol>
        <p>
          <strong>Überblick weiterer Aktivitäten</strong>
        </p>
        <ul>
          <li>
            Ca. 8-10 Auftritte in Essen und Umgebung pro Jahr:
            <br />
            Schulkonzerte, Weihnachtsmärkte, Kirchfeste, Essen-Original, Kray or
            die, Isinger Feld
            <br />
            K21 Düsseldorf Schulministerium (3 x 2008 und 2009, Oktober 2010);
            <br />
            Bildungssymposium der Landesregierung Messe Essen 2008, 2009, 2010,
            2011, 2012;
            <br />
            Prämienfeier der Unfallkasse NRW 2011, 2012; 2017
            <br />
            Stadtteilfeste (z.B. im Rahmen der Stadtteilpartnerschaft
            Steele-Kettwig), Burgfest
            <br />
            Burgaltendorf, IHK, „Meile der Kunst“, „Quirl“ (Bergisch-Gladbach)
            usw.,Laurentiusfest, Krayenale, Kulturpfadfest, Kray or die, Isinger
            Feld usw.
          </li>
          <li>Offene Probe mit Paul Kuhn im Dezember 1997</li>
          <li>Projekte mit Kunst (Filmmusik 1999), Tanz (2000, 2002)</li>
          <li>
            Musicals ("Dreigroschenoper" 2004; "West Side Story" 2006, "HAIR"
            2009 (inklusive Chor),
            <br />
            "Little Shop of Horrors" 2011 jeweils in ausgewählten
            Combo-Besetzungen
            <br />
            "Across the universe" mit Musik der Beatles Oktober 2013, "Spring
            awakening" 2015, "The Blues Brothers 2017
          </li>
          <li>
            Workshop und gemeinsames Konzert mit der WDR-Big-Band im Februar
            2014 in der Aula der Wolfkuhle
          </li>
          <li>Videoproduktion Band/Tanz (s. CD 3)</li>
          <li>
            Zahlreiche Konzertgäste wie Bernd Krämer (Albert Mangelsdorf; Klaus
            Lage), 2009: 78 Twins (mehrfache Träger des German Rock and Pop
            Award), 2010: Bernie Conrads (Songwriter für Stoppok, Peter Maffay)
            2011: Andrea Held, Sorgenkind, 2012: unser Star für Baku (ESC):
            Leonie Burgmer, 2013: Ten Cent Pistol, 2014: Ehemaligen-Band aus ca.
            40 Mitwirkenden und "ABack".
          </li>
        </ul>
        <p>
          Auszeichnung: Im August 2000 (27.8.00, siehe Anlage) errang die Band
          den ersten Platz beim „Sommer-Grand-Prix der Schulorchester“ im CentrO
          und gewann den Hauptpreis, eine CD-Produktion.
        </p>
      </div>
    </Page>
  );
}
